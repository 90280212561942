import React, { useState, useEffect } from "react"
import links from "../../utils/links"
import utils from "../../utils/utils"
import BlockCall from "../blockCall/blockCall"
import buttonClient from "../buttonClient/buttonClient"
import ButtonClient from "../buttonClient/buttonClient"
import ButtonHelp from "../buttonHelp/buttonHelp"
import Logo from "../logo/logo"
import PhoneAzure from "../phoneAzure/phoneAzure"
import PromoHandband from "../promoHandband/promoHandband"
import "./header.scss"
import config from "../../utils/config"

const Header = ({ isViewPromoOnHeader , forceIsScroll }) => {
  const [toggle, setToggle] = useState("")
  const [isScroll, setIsScroll] = useState(true)
  const [openNav, setOpenNav] = useState(false)
  const [openCall, setOpenCall] = useState(false)
  const [isPromoActive, setIsPromoActive] = useState(false)
  const [toggleActualPage, setToggleActualPage] = useState("")
  const [load, setLoad] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [paddingStyle, setPaddingStyle] = useState("")
  const [campagne, setCampagne] = useState("")

  useEffect(() => {
    if (typeof window !== 'undefined'){
      if (localStorage.getItem("campaign") != null){
        setCampagne(localStorage.getItem("campaign"))
      }
      else if (isFirstPage() && isThereParameters()){
        setCampagne(window.location.href.replace(config.URL_VEL + "/", ""))
        localStorage.setItem("campaign", window.location.href.replace(config.URL_VEL + "/", ""))
      }
    } 
  }, [])

  const genLinkHeader = (linkToRetrieve: string) => {
    if (isFirstPage()){
      if (isThereParameters()){
          return links.getLink(linkToRetrieve) + campagne
      }
      else{
        return links.getLink(linkToRetrieve)  
      }
    }
    else{
      return links.getLink(linkToRetrieve) + campagne
    }
  }
  
  const genLinkHeaderFormule = (linkToRetrieve: string) => {
    if (isFirstPage()){
      if (isThereParameters()){
        return links.getLink(linkToRetrieve) + campagne
      }
      else{
        return links.getLink(linkToRetrieve) 
      }
      
    }
    else{
      return links.getLink(linkToRetrieve) + campagne
    } 
  }
  
  const isFirstPage = () => {
    if (typeof window !== `undefined`){
      if (config.URL_VEL && (window.location.href.replace(config.URL_VEL, "") == "" || window.location.href.replace(config.URL_VEL + "/", "") == "") || (window.location.href.replace(config.URL_VEL, "").startsWith("?") || window.location.href.replace(config.URL_VEL + "/", "").startsWith("?"))){
        return true
      }
      else{
        return false
      }
    }
    else{
      return false
    }
  }
  
  const isThereParameters = () => {
    if (typeof window !== `undefined`){
      if (config.URL_VEL && window.location.href.replace(config.URL_VEL + "/", "").startsWith("?")){
        return true
      }
      else{
        return false
      }
    }
    else { return false }
  }
  
  const navItems = [
    {
      name: "offer",
      label: "Notre offre",
      url: genLinkHeaderFormule("formule"),
      iconMobile: "icon-operator",
      list: [],
      isDesktop: true,
    },
    {
      name: "material",
      label: "Notre matériel",
      url: genLinkHeader("materiel"),
      iconMobile: "icon-materials",
      list: [],
      isDesktop: true,
    },
    {
      name: "about",
      label: "A propos",
      url: "",
      iconMobile: "icon-eye",
      list: [
        { label: "Qui sommes-nous ?", url: genLinkHeader("quiSommesNous")},
        { label: "Notre réseau", url: genLinkHeader("reseau")},
      ],
      isDesktop: true,
    },
    {
      name: "daily",
      label: "Au quotidien",
      url: "",
      iconMobile: "icon-interest",
      list: [
        { label: "Nos conseils", url: genLinkHeader("conseils")},
        { label: "FAQ", url: genLinkHeader("faq")},
      ],
      isDesktop: true,
    },
    {
      name: "client",
      label: "Espace client",
      url: links.getLink("espaceClient"),
      iconMobile: "icon-profile",
      list: [],
      isDesktop: false,
    },
    // {
    //   name: "faq",
    //   label: "FAQ",
    //   url: links.getLink("faq"),
    //   iconMobile: "icon-black-interest",
    //   list: [],
    //   isDesktop: true,
    // },
    // {
    //   name: "conseils",
    //   label: "Nos conseils",
    //   url: links.getLink("conseils"),
    //   iconMobile: "icon-black-interest",
    //   list: [],
    //   isDesktop: true,
    // },
    /* {
      name: "diag",
      label: "Commencer un devis",
      url: links.getLink("diag"),
      iconMobile: "icon-start-diag-black",
      list: [],
      isDesktop: false,
    },*/
    //{name:"advice",label:"Nos conseils",url:"/conseils",iconMobile:"icon-black-document",list:[]}
  ]

  const getToggleActualPage = () => {
      if (typeof window !== "undefined") {
        if (window.location.href.includes(links.getLink("formule"))) {
          setToggleActualPage("offer")
        }
        else if (window.location.href.includes(links.getLink("materiel"))) {
          setToggleActualPage("material")
        }
        else if (window.location.href.includes(links.getLink("quiSommesNous")) || window.location.href.includes(links.getLink("reseau"))) {
          setToggleActualPage("about")
        }
        else if (window.location.href.includes(links.getLink("faq")) || window.location.href.includes("conseil")) {
          setToggleActualPage("daily")
        }
        else{
          setToggleActualPage("")
        }
      }
  }

  useEffect(() => {
    setIsMobile(utils.isMobileSize())
  }, [utils.isMobileSize()])

  useEffect(() => {
    getToggleActualPage()
  }, [])

  useEffect(() => {
    if (!forceIsScroll){
      setIsScroll(utils.topPositionScroll() !== 0)
      window.addEventListener("scroll", () => {
        setIsScroll(utils.topPositionScroll() !== 0)
      })
    }
    else {
      setIsScroll(true)
    }
    
  }, [])
  useEffect(() => {
    utils.lockScrollWinodws(openNav)
  }, [openNav])
  const handleClick = (bool = !openCall) => {
    setOpenNav(false)
    setOpenCall(bool)
    utils.lockScrollWinodws(bool)
  }
  const classStyle = item => {
    if (toggle === item.name || toggleActualPage === item.name) {
      if (item.isDesktop) {
        if (toggle === item.name && (item.name === "about" || item.name === "daily")){
          return "item-nav p-r fd-lg-c br-44 d-f ai-c ls-n px-2 py-1 mr-1 ml-1 py-lg-2 mr-lg-0 ml-lg-0 px-lg-0  ai-lg-fs jc-lg-fs fd-lg-c is-active is-open"
        }
        else{
          return "item-nav p-r fd-lg-c br-44 d-f ai-c ls-n px-2 py-1 mr-1 ml-1 py-lg-2 mr-lg-0 ml-lg-0 px-lg-0  ai-lg-fs jc-lg-fs fd-lg-c is-active"
        }
      }
      else {
        return "item-nav p-r br-44 d-n d-lg-f ai-c ls-n px-2 py-1 mr-1 ml-1 py-lg-2 mr-lg-0 ml-lg-0 px-lg-0 ai-lg-fs jc-lg-fs fd-lg-c "
      }
    } else {
      if (item.isDesktop) {
        return "item-nav p-r br-44 d-f ai-c ls-n px-2 py-1 mr-1 ml-1 py-lg-2 mr-lg-0 ml-lg-0 px-lg-0 ai-lg-fs jc-lg-fs fd-lg-c "
      } else {
        return "item-nav p-r br-44 d-n d-lg-f ai-c ls-n px-2 py-1 mr-1 ml-1 py-lg-2 mr-lg-0 ml-lg-0 px-lg-0 ai-lg-fs jc-lg-fs fd-lg-c "
      }
    }
  }

  const iconStyle = item => {
    if (toggle === item.name || toggleActualPage === item.name) {
      if (item.isDesktop) {
        if (toggle === item.name && (item.name === "about" || item.name === "daily")){
          return item.iconMobile.replace("icon-", "icon-black-")
        }
        else{
          return item.iconMobile.replace("icon-", "icon-black-")
        }
      }
      else {
        return item.iconMobile
      }
    } else {
      if (item.isDesktop) {
        return item.iconMobile
      } else {
        return item.iconMobile
      }
    }
  }

  const paddingSubListStyle = item => {
    if (!isMobile) {
      return "px-3"
    }
    else {
      return "padding-leftText"
    }
  }

  const paddingStyleSetter = () => {
    if (!isMobile) {
      setPaddingStyle("px-3")
    }
    else {
      setPaddingStyle("padding-leftText")
    }
  }
    

  useEffect(() => {
    paddingStyleSetter()
  }, [isMobile])

    return (
      <>
        <BlockCall handleClick={handleClick} openCall={openCall} />
        <div
          className={
            isPromoActive ? "header-container is-promo" : "header-container"
          }
        >
          <div className={isScroll ? "header-fixed is-active" : "header-fixed "}>
            <div className="w-100 bcg-2">
              <div className="wrapper">
                <div className="w-100 d-f jc-fe">
                  <ButtonHelp
                    handleClick={handleClick}
                    addClass="d-md-n d-f c-1 mr-2 "
                  />
                  <p className="mb-0 py-06 d-f ai-c">
                    <PhoneAzure />
                  </p>
                </div>
              </div>
            </div>
            <header className="header">
              <div className="wrapper p-r d-f ai-c jc-sb jc-lg-sb py-lg-2 bc-w">
                {/** MOBILE */}
                <div className=" fd-c ai-c jc-c d-n d-lg-f ">
                  <button
                    onClick={() => setOpenNav(!openNav)}
                    className={
                      openNav
                        ? "d-f jc-c ai-c hamburger hamburger--collapse is-active"
                        : "d-n d-lg-b hamburger hamburger--collapse "
                    }
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                  <p className="mb-0 fs-10 lh-20 c-1">
                    {openNav ? "Fermer" : "Menu"}
                  </p>
                </div>
                <div className="d-n d-lg-b py-1">
                  <div className="center-logo">
                    <Logo />
                  </div>
                </div>
                {/** DESKTOP */}
                {/** NAV */}
                <div
                  tabIndex={openNav ? 1 : -1}
                  className={
                    openNav
                      ? "nav-container bc-lg-w d-f ai-c is-active"
                      : "nav-container bc-lg-w d-f ai-c"
                  }
                >
                  <div className="py-1 d-lg-n">
                    <Logo />
                  </div>
                  <nav className="nav ml-4 ml-lg-0 px-lg-3 py-lg-3">
                    <ul className="ul d-f ai-c ai-lg-fs ">
                      <li className="d-n d-lg-f  item-nav p-r br-44 ai-c ls-n px-2 py-1 px-lg-0 py-lg-2 mr-lg-0 ml-lg-0 mr-1 ml-1">
                        <span
                          className={"d-n d-lg-b mr-2 icon-accueil"}
                          style={{ height: "24px", width: "24px" }}
                        ></span>
                        <a
                          href={genLinkHeader("home")}
                          className="fs-14 td-n nav-link"
                        >
                          {"Accueil"}
                        </a>
                      </li>
                      {navItems.map((item, index) => (
                        <li className={classStyle(item)} key={index}>
                          {item.list.length > 0 ? (
                            <>
                              <div className="sub-list d-f ai-c">
                                <span
                                  className={"d-n d-lg-b mr-2 " + iconStyle(item)}
                                  style={{ height: "24px", width: "24px" }}
                                ></span>
                                <input
                                  type="text"
                                  onBlur={() =>
                                    setTimeout(() => {
                                      setToggle("")
                                    }, 100)
                                  }
                                  onFocus={() =>
                                    setTimeout(() => {
                                      setToggle(item.name)
                                    }, 100)
                                  }
                                  style={{
                                    opacity: 0,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                />
                                <a
                                  role="button"
                                  tabIndex={0}
                                  className="c-p fs-14 td-n nav-link"
                                >
                                  {item.label}
                                </a>
                                <span className="icon-arrow-down-c1 ml-1 arrow"></span>
                              </div>
                              <div className="list-sub z-1 pt-1 py-lg-0">
                                <ul className="content oh box-shadow-s br-8 px-0 bc-w d-f fd-c ai-fs jc-fs">
                                  {item.list.map((list, i) => (
                                    <a
                                      href={list.url}
                                      key={i}
                                      className={"item-nav ls-n w-100 py-2 w-s-n td-n fs-14 lh-24 " + paddingStyle}
                                    >
                                      {list.label}
                                    </a>
                                  ))}
                                </ul>
                              </div>
                            </>
                          ) : (
                            <div className="d-f ai-c">
                              <span
                                className={"d-n d-lg-b mr-2 " + iconStyle(item)}
                                style={{ height: "24px", width: "24px" }}
                              ></span>
                              <a href={item.url} className="fs-14 td-n nav-link">
                                {item.label}
                              </a>
                            </div>
                          )}
                        </li>
                      ))}
                      <div className="tab">
                            {/** DIAG */}
                        <li className="d-n d-lg-b li diag-button">
                          <a
                            href={genLinkHeader("diag")}
                            className="btn-select-round td-n w-s-n bc-1 d-f ai-c jc-c c-w minDGwidth"
                          >
                            <span
                              className="mr-1  icon-start-diag d-ib"
                              style={{ height: "20px", width: "20px" }}
                            ></span>
                            <span className="fs-14 lh-24 cg-2">
                              Commencer un devis
                            </span>
                          </a>
                        </li>
                        {/** CALL */}
                        <li className="d-n d-lg-b call-button li py-1">
                          <button
                            onClick={() => handleClick()}
                            className="btn-select-round btn-help-mobile td-n w-s-n bc-1 d-f ai-c jc-c w-100 minDGwidth"
                          >
                            <span
                              className="icon-phone-white mr-1 d-ib"
                              style={{ height: "20px", width: "20px" }}
                            ></span>
                            <span className="fs-14 lh-24 bold">Être rappelé</span>
                          </button>
                        </li>
                      </div>
                    </ul>
                  </nav>
                </div>
                {/** CTA */}
                <div className="d-f ai-c">
                  <ButtonClient addClass={"espace-client d-lg-n lh-24"} />
                  <ButtonHelp
                    handleClick={handleClick}
                    addClass="btn-help d-md-f d-n "
                    isMobile={true}
                  />
                  <a
                    href={genLinkHeader("diag")}
                    className="td-n d-md-n btn-hover-c1 d-f ai-c c-p bc-1 c-w fw-b fs-14 lh-24 br-24 px-c20 py-1"
                  >
                    <span
                      style={{ height: "20px", width: "20px", minWidth: "20px" }}
                      className="mr-1 icon-start-diag"
                    ></span>
                    {"Commencer un devis"}
                  </a>
                </div>
              </div>
            </header>
  
            <PromoHandband
              isPromoActive={isPromoActive}
              isViewPromoOnHeader={isViewPromoOnHeader}
              setIsPromoActive={setIsPromoActive}
            />
          </div>
        </div>
      </>
    )
}

export default Header
