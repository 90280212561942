import React from "react"

const footerItem = ({ label, url }) => {
  return (
    <li className="px-4 li cg-3 py-md-1">
      <button
        onClick={() => (window.location.href = url)}
        className="cg-3 td-n fs-12 lh-16 ff-gbook d-b"
      >
        {label}
      </button>
    </li>
  )
}

export default footerItem
