import React, { useState, useRef, useEffect } from "react"
import Layout from "../../components/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import SliderCustom from "../../components/sliderCustom/sliderCustom"
import queryString from "query-string"
import "./index.scss"
import Seo from "../../components/seo/seo"
import tracking from "../../utils/tracking"
import links from "../../utils/links"

export const query = graphql`
  query {
    nodePageMentionsLegales {
      field_seo_description
      field_seo_titre
      field_titre
    }
    allNodeMentionsLegales {
      edges {
        node {
          id
          body {
            value
          }
          title
          field_order
        }
      }
    }
    allNodeConditionsGeneralesUtilisation {
      edges {
        node {
          id
          body {
            value
          }
          title
          field_order
        }
      }
    }
    allNodeCharteDesDonnees {
      edges {
        node {
          id
          body {
            value
          }
          title
          field_order
        }
      }
    }
    allNodeCookieLegale {
      edges {
        node {
          id
          body {
            value
          }
          title
          field_order
        }
      }
    }
  }
`

const index = ({ data = null }) => {

  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  const seoPage = {
    title: data.nodePageMentionsLegales.field_seo_titre,
    description: data.nodePageMentionsLegales.field_seo_description,
  }
  const navScroll = useRef(null)
  const formatData = str_index => {
    let result = []
    if (data) {
      const { edges } = data[str_index]
      if (edges) {
        edges.forEach(element => {
          result.push({
            id: element.node.id,
            title: element.node.title,
            content: element.node.body?.value,
            field_order: element.node.field_order,
          })
        })
      }
    }
    if (result) {
      result = result.sort((a, b) => (a.field_order > b.field_order ? 1 : -1))
    }
    return result
  }
  const itemsRef = useRef(null)
  const changeUrlParams = (nameValue: string) => {
    window.history.replaceState(
      {},
      "",
      window.location.pathname + "?onglet=" + nameValue + campagne.replace("?","&")
    )
    return
  }
  const [isMobile, setIsMobile] = useState(false)
  const [current, setCurrent] = useState(0)
  const [currentDetail, setCurrentDetail] = useState(null)
  const dataFormated = [
    {
      name: "mentions_legales",
      label: "Mentions légales",
      model: 0,
      data: formatData("allNodeMentionsLegales"),
    },
    {
      name: "cgu",
      label: "Conditions Générales d'Utilisation",
      model: 0,
      data: formatData("allNodeConditionsGeneralesUtilisation"),
    },
    {
      name: "politique",
      label: "Politique de données",
      model: 1,
      data: formatData("allNodeCharteDesDonnees"),
    },
    {
      name: "cookies",
      label: "Cookies",
      model: 1,
      data: formatData("allNodeCookieLegale"),
    },
  ]

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "cgupage")
    tracking.track("categorie", "autre")
    tracking.track("page_name", seoPage.title + (isTherecampagne ? "/" + campagne : ""))
    tracking.trackInit()
    /** Check PArams */
    const { search } = window.location
    if (search) {
      const queryParams = queryString.parse(search)
      const { onglet, target } = queryParams
      /** ONGLET */
      if (onglet) {
        const indexByOnglet = dataFormated.findIndex(el => el.name === onglet)
        if (indexByOnglet !== -1) {
          setCurrent(indexByOnglet)
          /** TARGET */
          if (target) {
            const indexTarget = dataFormated[indexByOnglet].data.findIndex(
              el => el.id === target
            )

            if (indexTarget !== -1) {
              setCurrentDetail(indexTarget)
              /*
                                <p className="ff-gbook fs-12 mt-3 mb-0">
                                Pour connaître les moyens de paiement acceptés, consultez
                                nos <a href={""}>CGU</a>.
                              </p>*/
            }
          }
        }
      }
    }
    /** Init Scroll */
    setIsMobile(window.innerWidth < 768)
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768)
    })
  }, [])
  useEffect(() => {
    if (itemsRef) {
      const _target = itemsRef.current
      if (_target) {
        const pos_top = _target.offsetTop
        window.scrollTo({ top: pos_top - 120, behavior: "smooth" })
      }
    }
  }, [currentDetail])
  return (
    <Layout isViewPromoOnHeader={true}>
      <Seo
        title={seoPage.title}
        description={seoPage.description}
        linkCanonical={"cgu/"}
      />
      <div className="bc-2">
        <h1 className="fs-28 lh-32 cg-7 ta-c py-7 mb-0">
          {data.nodePageMentionsLegales.field_titre}
        </h1>
      </div>
      <article className="wrapper-cgu d-f fd-c jc-c ai-c">
        <div className="nav-category-cgu d-f ai-fe">
          {isMobile ? (
            <>
              <div className="nav-scroll-mentions w-100">
                <SliderCustom
                  settings={{
                    className: "slider variable-width",
                    dots: false,
                    infinite: false,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: current,
                    variableWidth: true,
                  }}
                >
                  {dataFormated.map((el, index) => (
                    <div key={index} className=" ">
                      <button
                        onClick={() => {
                          setCurrent(index)
                          changeUrlParams(el.name)
                          setCurrentDetail(null)
                        }}
                        className={
                          current === index
                            ? "btn-category-cgu fs-14 lh-24 px-1 py-1 px-md-2 is-active "
                            : "btn-category-cgu fs-14 lh-24 px-1 py-1 px-md-2"
                        }
                      >
                        {el.label}
                      </button>
                    </div>
                  ))}
                </SliderCustom>
              </div>
            </>
          ) : (
            <div className="nav-scroll-mentions">
              <ul ref={navScroll} className="ul d-f ai-c w-100">
                {dataFormated.map((el, index) => (
                  <li key={index} className="li ">
                    <button
                      onClick={() => {
                        setCurrent(index)
                        changeUrlParams(el.name)
                        setCurrentDetail(null)
                      }}
                      className={
                        current === index
                          ? "btn-category-cgu fs-14 lh-24 px-1 py-1 px-md-2 is-active "
                          : "btn-category-cgu fs-14 lh-24 px-1 py-1 px-md-2"
                      }
                    >
                      {el.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <section className="list-cgu w-100 mt-5">
          {/** MODEL 0 */}
          {dataFormated[current].model === 0 && (
            <>
              <ul className="ul w-100">
                {dataFormated[current].data.length > 0 && (
                  <>
                    {dataFormated[current].data.map((el, index) => (
                      <li
                        ref={currentDetail === index ? itemsRef : null}
                        className={
                          currentDetail === index
                            ? "li item-cgu is-active"
                            : "li item-cgu"
                        }
                        key={index}
                      >
                        <button
                          onClick={e => {
                            setCurrentDetail(
                              currentDetail === index ? null : index
                            )
                          }}
                          className="btn-detail-cgu d-f ai-c jc-sb"
                        >
                          <div className="d-f fd-c content-title-cgu">
                            <div className="d-f ai-c jc-sb mb-1">
                              <div className="d-f fd-c">
                                <h3 className="c-1 ta-l mb-0 fs-16">
                                  {el.title}
                                </h3>
                              </div>
                            </div>
                            <div
                              tabIndex={-1}
                              className="previous"
                              dangerouslySetInnerHTML={{ __html: el.content }}
                            ></div>
                          </div>
                          <span
                            style={{ height: "7px", minWidth: "12px" }}
                            className="target-arrow icon-arrow-down-c1"
                          ></span>
                        </button>
                        <div
                          tabIndex={-1}
                          className="details"
                          dangerouslySetInnerHTML={{ __html: el.content }}
                        ></div>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </>
          )}
          {/** MODEL 1 */}
          {dataFormated[current].model === 1 && (
            <>
              {dataFormated[current].data.length > 0 && (
                <>
                  {dataFormated[current].data.map((el, index) => (
                    <div key={index} className="item-cgu  no-limit">
                      <h3 className="ff-gb fs-24">{el.title}</h3>
                      <div
                        className="details"
                        dangerouslySetInnerHTML={{ __html: el.content }}
                      ></div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </section>
      </article>
    </Layout>
  )
}

export default index
