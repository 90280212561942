import React from "react"
import { getTaxoLien } from "../../hooks/hooks"
import tracking from "../../utils/tracking"

const buttonClient = ({ addClass = "" }) => {
  const link = getTaxoLien("Espace client")
  const handleClick = () => {
    tracking.trackClick("click.navigation", "espace_client")
    window.open(link, "_blank")
  }
  return (
    <a
      className={
        addClass +
        "  c-p py-1  mr-2 c-1  br-24  fw-b td-n px-c20   fs-14  d-f ai-c"
      }
      onClick={() => handleClick()}
      target="_blank"
    >
      <span
        style={{ height: "15px", width: "15px" }}
        className="icon-profile d-b mr-1"
      ></span>
      Espace Client
      <span
        style={{ height: "8px", width: "11px" }}
        className="d-n d-md-ib icon-next-violet d-b ml-1"
      ></span>
    </a>
  )
}

export default buttonClient
