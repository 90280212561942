import React from "react"
import FooterItem from "./footerItem"
import tracking from "../../utils/tracking"
import "./footer.scss"

const dataFooter = [
  { label: "Mentions légales", url: "/cgu?onglet=mentions_legales" },
  { label: "CGU", url: "/cgu?onglet=cgu" },
  { label: "Politique de données", url: "/cgu?onglet=politique" },
  { label: "Cookies", url: "/cgu?onglet=cookies" },
  //{label:"CGV",url:"/"},
]

const socialsItem = [
  {
    url: "https://www.facebook.com/nexecur",
    icon: "icon-facebook",
    title: "facebook",
  },
  {
    url: "https://www.linkedin.com/company/nexecur/",
    icon: "icon-linkedin",
    title: "linkedin",
  },
  {
    url: "https://www.youtube.com/c/NEXECURGroupeCr%C3%A9ditAgricole/featured",
    icon: "icon-youtube",
    title: "youtube",
  },
]

const footer = () => {
  return (
    <footer>
      <div className="footer wrapper no-wrapper no-padding">
        <div className="py-3 d-f jc-sb ai-fs fd-md-c jc-md-c ai-md-c">
          <div className="socios d-f ai-c fd-md-c">
            <p className="fs-14 lh-24 cg-7 mb-0">
              Retrouvez toute l’actualité de Nexecur :{" "}
            </p>
            <ul className="ul d-f ai-c py-md-3">
              {socialsItem.map((el, index) => (
                <li className="d-f ai-c li" key={index}>
                  <div className="px-4 ">
                    <a
                      onClick={() => {
                        tracking.trackClick(
                          "click.navigation",
                          "footer_" + el.title
                        )
                        window.open(el.url, "_blank")
                      }}
                      className={"d-f c-p j-c ai-c " + el.icon}
                      style={{ height: "21px", width: "25px" }}
                    ></a>
                  </div>
                  {index !== socialsItem.length - 1 && (
                    <span
                      style={{ width: "4px", height: "4px" }}
                      className="bc-1 br-50"
                    ></span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <a
            className="fs-12 lh-16 cg-7 d-f ai-c td-n "
            target="_blank"
            href="https://www.nexecur.fr/"
          >
            <span
              className="icon-next-violet d-f mr-1 d-md-n"
              style={{ height: "10px", width: "14px" }}
            ></span>
            Aller sur Nexecur.fr
          </a>
        </div>
      </div>
      <ul className="ul list-link-footer d-f ai-c jc-c bcg-2 fd-md-c ">
        {dataFooter.map((el, index) => (
          <FooterItem key={index} label={el.label} url={el.url} />
        ))}
      </ul>
    </footer>
  )
}

export default footer
