/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactChildren, ReactChild } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/header"
import Footer from "../footer/footer"
import "./layout.scss"

interface AuxProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
  isViewPromoOnHeader: Boolean
  forceIsScroll: Boolean
}

const Layout = ({ isViewPromoOnHeader = false, children, forceIsScroll = false }: AuxProps) => {
  return (
    <>
      <Header isViewPromoOnHeader={isViewPromoOnHeader} forceIsScroll={forceIsScroll} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
